.Table {
	margin: 20px 0;
	background: white;
	padding: 8px 16px;
	border-radius: 5px;
}

.Row {
	padding: 10px;
	border-bottom: 1px solid #ededed;
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
	color: #9c9c9c;
}

.TableContent :last-child {
	border: none;
}

.JobRow {
}

.TableHeaderRow {
	font-weight: 500;
	color: #9c9c9c;
}

.Cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.NameCell {
	cursor: pointer;
}

.NameCell .Title {
	font-weight: 500;
	color: #4a90e2;
}

.NameCell .UID {
	color: #aaa;
	font-weight: 300;
	font-size: 12px;
	line-height: 12px;
}

.NameCell .Subtitle {
	color: #a09b9b;
	font-size: 14px;
}

.DeadlineCell .Title {
	font-size: 14px;
	font-weight: 500;
}

.DeadlineCell .Subtitle {
	font-size: 14px;
}

.CenterCell {
	align-items: center;
}

.Tag {
	border-radius: 5px;
	font-size: 12px;
	min-width: 50px;
	padding: 2px 5px;
	text-align: center;
}

.Tag.Active,
.Tag.Open {
	color: #4a90e2;
	background: #e2edfa;
}

.Tag .Success,
.Tag.Live {
	color: #5ec2b0;
	background: #cdf4ec;
}

.Tag.Pending {
	background-color: #f9eab0;
	color: #e09320;
}

.Tag.Partial {
	background-color: #feffd2;
	color: #ffd600;
}

.StatusCell .Inactive,
.Tag.Partial {
	color: #ababab;
	background: #efefef;
	border-radius: 5px;
	font-size: 12px;
	min-width: 50px;
	text-align: center;
	padding: 2px 5px;
}

.JobRow .ActionCell {
	opacity: 0;
}

.JobRow:hover .ActionCell {
	opacity: 1;
}

.Loadmore {
	text-align: center;
	padding: 10px;
	font-size: 14px;
	background: white;
	border-radius: 3px;
	color: #4a90e2;
	cursor: pointer;
}

.Loadmore:hover {
	background-color: #eee;
}

.Loadmore:active {
	background-color: #ddd;
}
