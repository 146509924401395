.container{
    background: #FFFFFF;
    box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    text-align: left;
    padding-bottom: 25px;
    max-height: 600px;
    overflow-y: auto;
}

.header{
    background: linear-gradient(203.68deg, #E071F2 6.87%, #FE8999 98.3%);
    box-sizing: border-box;
    border-radius: 14px;
    height: 136px;
    padding: 18px;
    background-image: url('./images/headerBackgroundimage.svg');
    text-align: left;
    vertical-align: middle;
}

.headerInner{
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(44px);
    border-radius: 8px;
    padding: 19px 22px 25px 17px;
    text-align: left;
    vertical-align: middle;
    display:grid;
    grid-template-columns: 1fr auto;
}

.buttonsContainer{
    text-align:center;
    vertical-align:middle;
    display: grid;
    grid-gap:16px;
    /* grid-template-columns:150px 1fr; */
    /* min-width:310px; */
    float:right;
}

.title{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    max-height: 33px;
    overflow:hidden;
    color: #232D4C;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.titleBox{
    display:flex;
    max-width: 370px;
    overflow:hidden;
    flex-direction: column;
}

.desc{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    margin-top: 4px;
    text-transform: capitalize;
    max-height: 34px;
    overflow:hidden;
}    

.appliedBox{
    padding: 9px 14px 9px 11px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 12px;
    /* float: right; */
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0075FF;
    vertical-align:middle;
    cursor:pointer;
}

.deletejobBox{
    padding: 9px 28px 9px 28px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 12px;
    /* float: right; */
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FF5670;
    vertical-align:middle;
    margin-left:16px;
    cursor:pointer;
}

.secondaryBox{
    padding: 16px 18px 18px 18px;
}

.categoryBox{
    max-width: 804px;
    /* height: 69px; */
    background: #F4F4F6;
    border-radius: 8px;
    padding: 14px 16px 14px 16px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.catTitle{
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    opacity: 0.5;
    margin-bottom: 4px;
}

.catBox{
    margin-right: 57px;
    /* display:inline-block; */
}

.cat{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    margin-top: 4px;
}

.thirdBox{
    text-align: left;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top;
    display:grid;
    grid-template-columns: 393px 1fr;
}

.jobdesc{
    min-height: 221px;
    vertical-align:top;
    padding-left: 18px;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    word-break: break-word;
}

.descpara{
    font-size: 14px;
    line-height: 17px;
    word-break: break-word;
    color: #232D4C;
    padding-right: 20px;
}

.list{
    padding-left: 18px;
    padding-top: 4px;
}

.schedule{
    padding-top: 18px;

}

.Qualifications{
    margin-top:20px;
    padding: 0 20px;
    margin-bottom: 20px;
}

.Qual{
    margin-bottom: 10px;
    text-transform: capitalize;
}
.College{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
}

.CourseList{
    font-size: 14px;
    line-height: 140.2%;
}

.Recipients{
    display: grid;
    grid-template-columns: 1fr auto auto;
    justify-content: space-between;
    padding: 3px 0;
    gap: 10px;
    text-transform: capitalize;
    align-items: center;
}

.Recipients:hover{
    background: #eee;
    
}

.Status{
    font-size: 14px;
    color: #666;
    font-weight: 300;
}

.Status::before{
    content: "";
    width: 10px;
    height: 10px;
    color:red;
    display: inline-block;
    border-radius: 5px;
}

.Status.pending::before{
    background-color:#ffdc65;
}

.Status.live::before{
    background-color: #81ff4f;
}

.Status.rejected::before{
    background-color: #ffa6a6;
}