.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a:link{
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Input {
  background: #FCFCFC;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 15px;
  width: 100%;
  font-size: 16px;
}

.Input::placeholder{
  color: #ADADAD;
}

.FormField{
  margin: 15px auto;
}

.Input:focus {
  outline: 1px solid #4A90E2;
}

.InputLabel{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  margin-bottom: 3px;
}

.Error{
  background: #FFE7E5;
  padding: 3px 5px;
  color: #FF6F65;
  font-size: 0.9em;
  line-height: 1.1em;
  border-radius: 3px;
}