.SearchRow{

    display: flex;
    max-width: 900px;
    margin:auto;
    margin-top: 22px;
    margin-bottom: -65px;
}

.SearchInput{
    font-size: 16px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
    padding:20px 27px;
    border-radius: 10px;
    border: 1px solid #D3D5DB;
    box-shadow: 0px 22px 24px -20px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.SearchInput:focus{
    outline: none;
    border-color: #4A90E2;
}

.Icon{
    display: block;
}