.Skill{
    background: #C4C4C4;
    border-radius: 5px;
    padding: 3px 7px;
    font-size: 12px;
    /* display: inline-block; */
    cursor: pointer;
    max-width: 100%;
    display: flex;
    gap: 5px;
}

.Skill span{
    overflow-wrap: break-word;
    max-width: 100%;
    /* flex-basis: 100%; */
    display: block;
}

.SkillContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 10px auto ;

}

.Cross{
    filter: invert(0.7);
}