
.InputRow{
  min-height: 90px;
  padding-bottom: 10px;
}

.InputDiv{
  position: relative;
  /* margin-bottom: 17px; */
  background-color: white;
  border: 1px solid #D3D5DB;
  border-radius: 10px;
  display: flex;
  align-items: baseline;
}

.InputDiv.ErrorInput{
  border-color: #FF6565;
}

.InputElement {
  color:#232D4C;
  /* color: #333333; */
  /* font-size: 15px; */
  font-size: 0.9em;
  background-color: transparent;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  padding: 11px 20px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.1s ease-in;
  padding: 18px 16px;
  padding: 11px 16px;
  /* margin-right:7px; */
}

.InputElement:focus {
  border-color: #4A90E2;
  outline: none;
}

.InputLabel {
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
  color:#232D4C;
}

.RadioBox{
  font-size: 0.9em;
  margin-top:1em;
}
.Option{
  color:#232D4C;
  margin-right:20px;
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.Prefix,
.Postfix
{
  color: #232D4C;
  opacity: 0.3;
  padding:12px
}
.Prefix{
  padding-right: 0;
}
.Postfix{
  padding-left: 0;
}

.Errors {
  /* background: #FF6565; */
  /* border-radius: 0px 0px 10px 10px; */
  /* padding: 10px 10px 4px 10px; */
  /* font-size: 0.9em;
  /* margin-top: -10px; 
  color: #FF6565;
  margin-left:10px;
  font-weight: 100; */

  font-size: 0.8em;
  background: #FF6565;
  color: white;
  margin-left: 4px;
  font-weight: 300;
  padding: 2px 6px;
  border-radius: 3px;
}


/* Floating input */
.FloatingInputElement {
  color: #333333;
  font-size: 15px;
  background-color: transparent;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  padding: 11px 20px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.1s ease-in;
  padding: 18px 16px;
}

.FloatingInputElement:-webkit-autofill {
  background-color: #FAFFBD !important;
  background-image:none !important;
  color: #000000 !important;
}


.FloatingInputLabel {
  position: absolute;
  transition: background-color 0.15s ease-in-out,
    color 0.1s ease-in-out, transform 0.15s ease-in-out;
  top: -5px;
  background: white;
  left: 11px;
  padding: 0 5px;
  font-size: 12px;
  color: #959595;
  transform-origin: left;
}

.InputElement:placeholder-shown ~ .FloatingInputLabel {
  color: #959595;
  background-color: transparent;
  transform: translateY(24px) scale(1.25);
  cursor: text;
}

.InputElement:focus {
  border-color: #4A90E2;
  outline: none;
}

.InputElement:focus ~ .FloatingInputLabel {
  color:#4A90E2;
}

.InputElement:focus:placeholder-shown ~ .FloatingInputLabel {
  color: #959595;
}

.FloatingInputElement::placeholder {
  opacity: 0;
} 