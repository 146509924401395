.Table {
    margin: 20px 0;
    background: white;
    padding: 8px 16px;
    border-radius: 5px;
}

.Row{
    padding: 10px;
    border-bottom: 1px solid #EDEDED;;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr  1fr;
    color: #9C9C9C;
}

.TableContent :last-child{
    border: none;
}

.JobRow{

}



.TableHeaderRow{
    font-weight: 500;
    color: #9C9C9C;
}

.Cell{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
}


.NameCell{
    cursor: pointer;
}

.NameCell .Title{
    font-weight: 500;
    color: #4A90E2;
    font-size: 16px;
}

.NameCell .Subtitle{
    color: #A09B9B;
    font-size: 14px;
}

.DeadlineCell .Title{
    font-size: 14px;
    font-weight: 500;
}

.DeadlineCell .Subtitle{
    font-size: 14px;
}


.CenterCell{
    align-items: center;
}

.Tag {
    border-radius: 5px;
    font-size: 12px;
    min-width: 50px;
    padding: 2px 5px ;
    text-align: center;
}

.Tag.Active,
.Tag.Open
{
    color: #4A90E2;
    background: #E2EDFA;
   
}



.Tag.Success,
.Tag.Verified{
    color: #5EC2B0;
    background: #CDF4EC;
}

.Tag.Pending{
    background-color: #F9EAB0;
    color: #E09320;
}

.Tag.Partial{
    background-color: #FEFFD2;
    color: #FFD600;
}

.StatusCell .Inactive,
.Tag.Unverified
{
    color: #ABABAB;
    background: #EFEFEF;
    border-radius: 5px;
    font-size: 12px;
    min-width: 50px;
    text-align: center;
    padding: 2px 5px ;
}

.JobRow .ActionCell{
    opacity: 0;
}

.JobRow:hover .ActionCell{
    opacity: 1;
}

.Loadmore{
    text-align: center;
    padding: 10px;
    font-size: 14px;
    background: white;
    border-radius: 3px;
    color: #4a90e2;
    cursor: pointer;   
}

.Loadmore:hover{
    background-color: #eee;
}

.Loadmore:active{
    background-color: #ddd;
}

