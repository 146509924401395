.Toast{
    /* position: fixed;
    right: 40px;
    bottom: 20px; */
    padding: 18px 40px;
    background-color: #44D5BA;
    color: #fff;
    border-radius: 10px;
    padding-right: 0px;
    transition: opacity 1s ease-in-out;
}

.Cross{
    height: 10px;
}

.CrossBtn{
    margin: -30px 0;
    border: none;
    display: inline-flex;
    padding: 20px 20px;
    margin-left: 3px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    outline: none;
}

.Toast:hover .CrossBtn{
    opacity: 1;
}


.ToastContainer {
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    z-index: 200;
}

.ToastEnter{
    opacity: 0;
    transform:translateX(0%) scale(0.7);
}

.ToastEnterActive{
    opacity: 1;
    transform: translateX(0) scale(1);
    transition: transform 0.3s ease-out, opacity 0.3s ease-in-out;
}

.ToastExit {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
.ToastExitActive {
    opacity: 0;
    transform: translateX(0) scale(0.7);
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }