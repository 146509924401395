.outercontainer{
    width:100%;
    padding: 20px 31px 0px 31px;
    display:grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
}

.innercontainer{
    background: #F4F4F6;
    padding-top:10px;
    padding-left: 13px; 
    padding-right: 13px;
    
    border-radius: 10px;
    vertical-align: top;
}

.contactContainer{
    vertical-align: top;
    /* height: 108px; */
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px 26px 22px 26px;
    /* display:inline-block; */
    /* margin-left: 30px; */
    /* width:376px; */
    /* width: 50%; */
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.contactContainer.Loading{
    background: #f4f4f4;
    color:transparent;
    border:none;
}

.contactContainer.Loading img,
.contactContainer.Loading .number{
    opacity: 0;
}

.candidatetaggertext{
    height: 17px;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.numberdiv{
    padding-top:8px;
    vertical-align: top;

}

.number{
    height: 19px;
    font-size: 16px;
    line-height: 19px;
    color: #9196A5;
    display:inline-block;
    vertical-align: top;
    padding-top:-4px;
    padding-left: 11px;
}




.spantext{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4A90E2;
}

.buttoncontainer{
    padding:12px 0 22px 0; 
    display: flex;
    flex-direction: row;
    gap:16px;
}

.printOnly{
    display: none;
}




@media print {
    .innercontainer, .contactContainer{
      display:none;
    }
    .printOnly{
        display: block;
        text-transform: lowercase;
    }
    .outercontainer{
        grid-template-columns: 1fr;
        text-align: right;
    }
    .numberDiv{
        padding-top:0;
    }
  }
