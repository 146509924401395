.Container{
    padding: 30px;
    min-height: 400px;
    word-wrap: break-word;
    max-height: 80vh;
    overflow-y: auto;
}

.LogoContainer{
    width: 130px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    overflow:hidden;
}

.Logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Header{
    display: grid;
    grid-template-columns: auto 1fr 150px;
    gap: 40px
}

.Name{
    font-size: 22px;
    font-weight: 700;
}



.Field{

    margin-top: 17px;
}

.Field .Sub{
    font-size: 14px;
    color: #9C9C9C;
}

.Field .Title{

}