.ProfilePictureContainer{
    width: 100%;
    height: 100%;
    position: relative;
}

.ProfilePictureContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProfilePictureContainer .ProfilePicture{
    position: absolute;
    top:0;
    left:0;
    transition: opacity 0.2s ease-in-out;
}