.Save{
    width: 150px;
    margin: auto;
    display: block;
    padding: 15px;
    margin-top: 20px;
}

.Form{
    padding: 40px;
    padding-top: 20px;

}

.FromNow{
    float: right;
    color: #4a90e2;
    font-size: 14px;
}

.DeadlineLabel{
    justify-content: space-between;
}