.Nav{
    display: flex;
    flex-direction: column;
    margin-left:-40px;
}

.Nav a {
    /* font-size: 14px; */
    /* font-weight: 300; */
    padding : 10px 0px;
    padding-left: 40px;
    transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
    border-radius: 0px 5px 5px 0px;
    color: #777;
    margin-bottom: 5px;
}


.Nav a.ActiveLink {
    background-color: #4A90E2;
    color: white;
}

.Nav a:hover{
    background-color: #ddd;
}



a.ActiveLink:hover{
    /* background-color: #0D9AE0; */
    transform-origin: left;
    background-color: #4A90E2;
}