.Dropdown *:focus{
    outline: none;
    background: #0e7dff17;
}

.InputDisplay{
    padding:10px
}

.Dropdown{
    position: relative;
    cursor: pointer;
}

.MoreIcon{
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.6;
    width: 20px;
}

.DropdownMenu{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    /* font-size: 0.9em; */
    z-index: 20;
}
.DropdownMenu::before{
    box-shadow: 0px 4px 15px 0px #0000002b;
    content: "";
    top:0;
    left:0;
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #0000001f;
    border-radius: 10px;
}

.DropdownMenuContent{
    position: relative;
    width: 100%;
    height: 100%;
    left:1px;
    top:1px;
    border-radius: 10px;
    overflow: hidden;
}

.DropdownMenuEnter::before{
    transform-origin: top;
    transform:scaleY(0.5);
    opacity: 0;
}
.DropdownMenuEnterActive::before{
    transform:scaleY(1);
    opacity: 1;
    transition: transform .2s ease-out, opacity 0.2s ease-in-out ;
}

.DropdownMenuEnter .DropdownMenuContent{
    opacity: 0;
}
.DropdownMenuEnterActive .DropdownMenuContent{
    opacity: 1;
    transition: opacity .1s ease-in-out;
}



.DropdownMenuExit::before{
    transform-origin: top;
    transform:scaleY(1);
    opacity: 1;
}
.DropdownMenuExitActive::before{
    transform:scaleY(0.5);
    opacity: 0;
    transition: transform .2s ease-out 0.05s, opacity 0.2s ease-in-out 0.05s ;
}

.DropdownMenuExit .DropdownMenuContent{
    opacity: 1;
}
.DropdownMenuExitActive .DropdownMenuContent{
    opacity: 0;
    transition: opacity .15s ease-in-out;
}


.SearchInput{
    padding:10px 15px;
    border:none;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
}

.SearchIcon{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-39%);
    opacity: 0.3;
    height: 19px;
}

.SearchInputDiv{
    position: relative;
}

.OptionList{
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
}

.OptionList::-webkit-scrollbar {
    width: 8px;
  }


.OptionList::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 10px;
  }
  

.List::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

.Option{
    padding:12px 15px;
    cursor: pointer;
    display: flex;
    gap:10px;
    align-items: center;
    transition: background-color 0.05s ease-in-out;
}
.Option input{
    pointer-events: none;
}

.Option:hover{
    background: #eee;
}

.Option:active{
    background: #ddd;
}

.Option.Selected{
    background-color: #4A90E2;
    color: white;
}

.NoOptions{
    padding: 10px 15px;
    opacity: 0.5;
    display: block;
}