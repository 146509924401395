@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif ;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html { margin-right: calc( 100% - 100vw ); } */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a:link{
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Input {
  background: #FCFCFC;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 15px;
  width: 100%;
  font-size: 16px;
}

.Input::-webkit-input-placeholder{
  color: #ADADAD;
}

.Input:-ms-input-placeholder{
  color: #ADADAD;
}

.Input::placeholder{
  color: #ADADAD;
}

.FormField{
  margin: 15px auto;
}

.Input:focus {
  outline: 1px solid #4A90E2;
}

.InputLabel{
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 16px;
  margin-bottom: 3px;
}

.Error{
  background: #FFE7E5;
  padding: 3px 5px;
  color: #FF6F65;
  font-size: 0.9em;
  line-height: 1.1em;
  border-radius: 3px;
}
.Button_Button__3XPGi
{
    border-radius: 10px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
    font-size: 16px;
    width: 100%;
    padding: 1em 2em;
    cursor: pointer;
    position: relative;
    outline: none;
    border: 1px solid #4A90E2;
    color:#4A90E2;
    background: white;
    box-shadow: 0px 0px 0px 0px #0001;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, -webkit-filter 0.1s ease-in-out;
    transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out, -webkit-filter 0.1s ease-in-out;
}

.Button_Primary__1v46q 
{
    background: #4A90E2;
    color:white;
    border: none;
}

.Button_Disabled__3nwnW{
    color:white;
    background: #D3D5DB;
    border: none;
}

.Button_Button__3XPGi:hover {
    transform: scale(1.01);
    box-shadow: 0px 6px 8px 0px #0001;
}

.Button_Button__3XPGi:active{
    -webkit-filter: brightness(0.95);
            filter: brightness(0.95);
    transform: scale(0.99);
}


.Button_Loading__g48ck{
    cursor: default;
    outline: none;
}


.Button_Loading__g48ck::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: Button_swipe__1TfS3 infinite 1.5s ease-out;
    background: #0001;
    transform-origin: left;
}

@keyframes Button_swipe__1TfS3 {
    0% { transform: scaleX(0); opacity: 1;}
    70% {transform: scaleX(1); opacity: 1;}
    100%{ transform: scaleX(1); opacity: 0;}
}

.Input_InputRow__279aA{
  min-height: 90px;
  padding-bottom: 10px;
}

.Input_InputDiv__2eiRg{
  position: relative;
  /* margin-bottom: 17px; */
  background-color: white;
  border: 1px solid #D3D5DB;
  border-radius: 10px;
  display: flex;
  align-items: baseline;
}

.Input_InputDiv__2eiRg.Input_ErrorInput__3Xp5U{
  border-color: #FF6565;
}

.Input_InputElement__1_ImU {
  color:#232D4C;
  /* color: #333333; */
  /* font-size: 15px; */
  font-size: 0.9em;
  background-color: transparent;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  padding: 11px 20px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.1s ease-in;
  padding: 18px 16px;
  padding: 11px 16px;
  /* margin-right:7px; */
}

.Input_InputElement__1_ImU:focus {
  border-color: #4A90E2;
  outline: none;
}

.Input_InputLabel__2ajUc {
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
  color:#232D4C;
}

.Input_RadioBox__15wMi{
  font-size: 0.9em;
  margin-top:1em;
}
.Input_Option__2gifK{
  color:#232D4C;
  margin-right:20px;
  display: inline-flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

.Input_Prefix__3xjXz,
.Input_Postfix__1dt-8
{
  color: #232D4C;
  opacity: 0.3;
  padding:12px
}
.Input_Prefix__3xjXz{
  padding-right: 0;
}
.Input_Postfix__1dt-8{
  padding-left: 0;
}

.Input_Errors__3GxHc {
  /* background: #FF6565; */
  /* border-radius: 0px 0px 10px 10px; */
  /* padding: 10px 10px 4px 10px; */
  /* font-size: 0.9em;
  /* margin-top: -10px; 
  color: #FF6565;
  margin-left:10px;
  font-weight: 100; */

  font-size: 0.8em;
  background: #FF6565;
  color: white;
  margin-left: 4px;
  font-weight: 300;
  padding: 2px 6px;
  border-radius: 3px;
}


/* Floating input */
.Input_FloatingInputElement__3j5Q3 {
  color: #333333;
  font-size: 15px;
  background-color: transparent;
  font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif ;
  padding: 11px 20px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.1s ease-in;
  padding: 18px 16px;
}

.Input_FloatingInputElement__3j5Q3:-webkit-autofill {
  background-color: #FAFFBD !important;
  background-image:none !important;
  color: #000000 !important;
}


.Input_FloatingInputLabel__UoBzE {
  position: absolute;
  transition: background-color 0.15s ease-in-out,
    color 0.1s ease-in-out, transform 0.15s ease-in-out;
  top: -5px;
  background: white;
  left: 11px;
  padding: 0 5px;
  font-size: 12px;
  color: #959595;
  transform-origin: left;
}

.Input_InputElement__1_ImU:-ms-input-placeholder ~ .Input_FloatingInputLabel__UoBzE {
  color: #959595;
  background-color: transparent;
  transform: translateY(24px) scale(1.25);
  cursor: text;
}

.Input_InputElement__1_ImU:placeholder-shown ~ .Input_FloatingInputLabel__UoBzE {
  color: #959595;
  background-color: transparent;
  transform: translateY(24px) scale(1.25);
  cursor: text;
}

.Input_InputElement__1_ImU:focus {
  border-color: #4A90E2;
  outline: none;
}

.Input_InputElement__1_ImU:focus ~ .Input_FloatingInputLabel__UoBzE {
  color:#4A90E2;
}

.Input_InputElement__1_ImU:focus:-ms-input-placeholder ~ .Input_FloatingInputLabel__UoBzE {
  color: #959595;
}

.Input_InputElement__1_ImU:focus:placeholder-shown ~ .Input_FloatingInputLabel__UoBzE {
  color: #959595;
}

.Input_FloatingInputElement__3j5Q3::-webkit-input-placeholder {
  opacity: 0;
}

.Input_FloatingInputElement__3j5Q3:-ms-input-placeholder {
  opacity: 0;
}

.Input_FloatingInputElement__3j5Q3::placeholder {
  opacity: 0;
} 
.Dropdown_Dropdown__3ODYN *:focus{
    outline: none;
    background: #0e7dff17;
}

.Dropdown_InputDisplay__3F_n1{
    padding:10px
}

.Dropdown_Dropdown__3ODYN{
    position: relative;
    cursor: pointer;
}

.Dropdown_MoreIcon__rmWZB{
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.6;
    width: 20px;
}

.Dropdown_DropdownMenu__3aHMA{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    /* font-size: 0.9em; */
    z-index: 20;
}
.Dropdown_DropdownMenu__3aHMA::before{
    box-shadow: 0px 4px 15px 0px #0000002b;
    content: "";
    top:0;
    left:0;
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #0000001f;
    border-radius: 10px;
}

.Dropdown_DropdownMenuContent__1uI0H{
    position: relative;
    width: 100%;
    height: 100%;
    left:1px;
    top:1px;
    border-radius: 10px;
    overflow: hidden;
}

.Dropdown_DropdownMenuEnter__2BEdW::before{
    transform-origin: top;
    transform:scaleY(0.5);
    opacity: 0;
}
.Dropdown_DropdownMenuEnterActive__mJck7::before{
    transform:scaleY(1);
    opacity: 1;
    transition: transform .2s ease-out, opacity 0.2s ease-in-out ;
}

.Dropdown_DropdownMenuEnter__2BEdW .Dropdown_DropdownMenuContent__1uI0H{
    opacity: 0;
}
.Dropdown_DropdownMenuEnterActive__mJck7 .Dropdown_DropdownMenuContent__1uI0H{
    opacity: 1;
    transition: opacity .1s ease-in-out;
}



.Dropdown_DropdownMenuExit__2uR8b::before{
    transform-origin: top;
    transform:scaleY(1);
    opacity: 1;
}
.Dropdown_DropdownMenuExitActive__1KaZn::before{
    transform:scaleY(0.5);
    opacity: 0;
    transition: transform .2s ease-out 0.05s, opacity 0.2s ease-in-out 0.05s ;
}

.Dropdown_DropdownMenuExit__2uR8b .Dropdown_DropdownMenuContent__1uI0H{
    opacity: 1;
}
.Dropdown_DropdownMenuExitActive__1KaZn .Dropdown_DropdownMenuContent__1uI0H{
    opacity: 0;
    transition: opacity .15s ease-in-out;
}


.Dropdown_SearchInput__3-HRD{
    padding:10px 15px;
    border:none;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
}

.Dropdown_SearchIcon__1biwI{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-39%);
    opacity: 0.3;
    height: 19px;
}

.Dropdown_SearchInputDiv__1d0CA{
    position: relative;
}

.Dropdown_OptionList__2a6gx{
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
}

.Dropdown_OptionList__2a6gx::-webkit-scrollbar {
    width: 8px;
  }


.Dropdown_OptionList__2a6gx::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 10px;
  }
  

.Dropdown_List__34rUF::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

.Dropdown_Option__24EFJ{
    padding:12px 15px;
    cursor: pointer;
    display: flex;
    grid-gap:10px;
    gap:10px;
    align-items: center;
    transition: background-color 0.05s ease-in-out;
}
.Dropdown_Option__24EFJ input{
    pointer-events: none;
}

.Dropdown_Option__24EFJ:hover{
    background: #eee;
}

.Dropdown_Option__24EFJ:active{
    background: #ddd;
}

.Dropdown_Option__24EFJ.Dropdown_Selected__1utWR{
    background-color: #4A90E2;
    color: white;
}

.Dropdown_NoOptions__3jiXI{
    padding: 10px 15px;
    opacity: 0.5;
    display: block;
}
.ErrorBox_ErrorBox__C-L8P{ 
    
    
    background: #ff000017;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    color: #f04949;
    margin-top: -16px;
    margin-bottom: 16px;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
    position: relative;
}


.ErrorBox_ErrorBox__C-L8P p {
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-weight: 300;
    position: absolute;
    opacity: 0;

}

.ErrorBox_ErrorBox__C-L8P.ErrorBox_Active__1kFDS
{
    opacity: 1;
}

.ErrorBox_ErrorBox__C-L8P.ErrorBox_Active__1kFDS p
{
    animation: ErrorBox_fadein__39iPT 0.2s forwards ease-in-out 0.15s;
}

@keyframes ErrorBox_fadein__39iPT {
    from {opacity: 0;}
    to {opacity: 1;}
}
.Login_LoginGrid__3xjdN
{
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: minmax(540px, 1fr) 1.5fr;
    height: 100vh;
}

.Login_LoginSide__35BhN 
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left:80px;
    padding-right:120px
}

.Login_Logo__1gHtL
{
    width: 120px;
}

.Login_IllustrationSide__2rt29
{
    background: #F4F4F6;
    overflow: hidden;
    position: relative;
}

.Login_LoginShapes__1bL5G{
    padding-left:10%;
    width: 100%;
    height: 100%;
    object-position: 0% 100%;
    object-fit: cover;
}

.Login_H1__1j1H5 
{
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 32px;
    margin-top:23px;
    color: #0D0C21;
}

.Login_linksContainer__qXWX0{
    padding-top:14px;
}

.Login_linkText__1G41p{
    font-size: 16px;
    line-height: 19px;
    color: #4A90E2;
}


.Partners_PartnersModal__1yCbK{
    position: absolute;
    width: 60%;
    max-width: 600px;
    height: 80vh;
    top: 50%;
    left: 50%;
    background-color: white;
    background: #FFFFFF;
    box-shadow: 24px 24px 33px -10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transform: translate(-40%, -50%);
    overflow: hidden;
    padding-bottom: 20px;
}

.Partners_TopBar__3qucO{
    background-color: #232D4C;
    padding : 16px;
    color:white;
    display: flex;
    grid-gap:20px;
    gap:20px;
    align-items: baseline;
}

.Partners_DotContainer__1WIf1{
    display: flex;
    grid-gap:6px;
    gap:6px;
}

.Partners_DotContainer__1WIf1 span{
    height: 8px;
    width: 8px;
    border-radius: 5px;
    background: white;
}

.Partners_DotContainer__1WIf1 :nth-child(2){
    opacity: 0.66;
}

.Partners_DotContainer__1WIf1 :nth-child(3){
    opacity: 0.33;
}

.Partners_PartnersContent__MzKJr{
    height: calc((100% - 56px)/2);
    padding: 20px 40px;
    padding-top: 1px;
}


.Partners_PartnersGrid__X5R7s{
    max-height: calc(100% - 64px);
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-gap:20px;
    gap:20px;
    align-items: center;
    justify-content: center;
}

.Partners_PartnerTitle__sSJRZ{
    padding: 20px 0px;
}

.Partners_PartnerTile__1jsJB{
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}

.Partners_PartnerTile__1jsJB span{
    font-size: 14px;
    line-height: 1.1em;
    font-weight: 300;
    margin-top: 10px;
    display: block;
    color: #5c5c5c;
    height: 20px;
}

.Partners_Logo__1j-hs{
    max-height:90px;
    display: block;
    margin: auto;
    object-fit: contain;
}
.StudentsHeader_StudentsHeader__1WpCX {
    background: #232D4C;
    border-radius: 0px 0px 40px 40px;
    color: white;
    padding: 40px 52px;
    text-align: center;
}

.StudentsHeader_Title__2k9s1 {
    font-size: 36px;
    font-weight: 500;
    margin-top:30px;
    margin-bottom: 5px;
}

.StudentsHeader_Loading__znwLu .StudentsHeader_Title__2k9s1{
    background-color: #e7e7e7;
    opacity: 0.2;
    color:transparent;
    /* height: 41.6px; */
    width: 80%;
    border-radius: 5px;
    max-width: 500px;
    margin-left:auto;
    margin-right: auto;
}

.StudentsHeader_SubTitle__2HT3h 
{
    font-weight: 500;
    font-size: 26px;
    opacity: 0.5;
}

.StudentsHeader_Loading__znwLu .StudentsHeader_SubTitle__2HT3h{
    background-color: #e7e7e7;
    opacity: 0.2;
    content-visibility: hidden;
    height: 29.6px;
    width: 60%;
    border-radius: 5px;
    max-width: 350px;
    margin-left:auto;
    margin-right: auto;
}


.StudentsHeader_BackButton__3fKFL {
    position: absolute;
    left: 50px;
}
.SearchBar_SearchRow__1PISj{

    display: flex;
    max-width: 900px;
    margin:auto;
    margin-top: 22px;
    margin-bottom: -65px;
}

.SearchBar_SearchInput__2SPkq{
    font-size: 16px;
    font-family: "poppins", 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
    padding:20px 27px;
    border-radius: 10px;
    border: 1px solid #D3D5DB;
    box-shadow: 0px 22px 24px -20px rgba(0, 0, 0, 0.25);
    width: 100%;
}

.SearchBar_SearchInput__2SPkq:focus{
    outline: none;
    border-color: #4A90E2;
}

.SearchBar_Icon__2w8cD{
    display: block;
}
.tagsearch_BottomNav__3rikO {
    margin-left: 20px;
    background-color: #fff;
    border-radius: 10px;;
    position: relative;
    box-shadow: 0px 29px 34px -20px rgba(0, 0, 0, 0.25);
}

.tagsearch_NavLinks__38Hap{
    display: flex;
    position: relative;
    z-index: 1;
    height: 100%;
}


.tagsearch_ActiveMarker__1ZHeB{
    width: 100px;
    transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
    position:absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.tagsearch_ActiveMarker__1ZHeB div{
    border-radius: 10px 10px 10px 10px;
    background: #4A90E2;
    height: 100%;
    width: 100px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
    /* border-radius: 14px; */
    /* transition: transform 0.3s cubic-bezier(0.64, 0, 0.1, 0.9); */
}

/* .ActiveMarker div.Moving{
    animation: skew 0.3s cubic-bezier(0.64, 0, 0.1, 0.9);
} */

.tagsearch_ActiveMarker__1ZHeB div.tagsearch_MovingLeft__RUSg6 {
    transform-origin: right;
    animation: tagsearch_skew__1C-Su 0.4s ease-in-out;
}

.tagsearch_ActiveMarker__1ZHeB div.tagsearch_MovingRight__3iSfl{
    transform-origin: left;
    animation: tagsearch_skew__1C-Su 0.4s ease-in-out;
}


@keyframes tagsearch_skew__1C-Su {
    0%{transform: scaleX(1)}
    50%{transform: scaleX(0.5)}
    100%{transform: scaleX(1)}
}

.tagsearch_NavLink__3QWX3{
    width: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding:18px;
    transition: background-color 0.15s ease-in-out;
    cursor: pointer;
    height: 100%;
}

.tagsearch_NavLink__3QWX3 .tagsearch_Icon__2unia{
    height: 23px;
    transform-origin: 50% 120%;
    transition: transform 0.15s ease-in-out;
}

.tagsearch_NavLink__3QWX3 span {
    margin-top:5px;
    font-size:14px;
    color:#9196A5;
}

/* .NavLink:hover{
    background-color: #f3f3f3;
} */

.tagsearch_NavLink__3QWX3:hover .tagsearch_Icon__2unia{
    transform: scale(1.05);
}

.tagsearch_NavLink__3QWX3.tagsearch_Active__3PAUW span{
    color:#4A90E2;
}
.StudentCard_StudentCard__3AWRp {
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 14px;    
    background: white;
    padding: 22px 18px;
    padding-right: 0;
}

.StudentCard_StudentImage__3pgJv{
    border-radius: 12px;
    width: 106px;
    height: 103px;
    object-fit: cover;
}

.StudentCard_StudentInfo__1pPig{
    display: flex;
    margin-bottom: 16px;
    grid-gap: 16px;
}

.StudentCard_StudentName__LQ3f5{
    font-size: 24px;
    color:#232D4C;
}
.StudentCard_StudentSubTitle__3agvc{
    color:#9196A5;
    font-size: 12px;
    line-height: 14px;
    margin-top:2px
}

.StudentCard_RatingAndView__1IYQo 
{
    display: flex;
    grid-gap:20px;
    margin-top:12px;
}

.StudentCard_Rating__10-Hu{
    font-size: 20px;
    padding:  9px 27px 14px 19px;
    background: #F4F4F6;
    border-radius: 10px;
    flex-shrink: 0;
}
.StudentCard_Rating__10-Hu :first-child{
    font-weight: 500;
    color: gray;
}
.StudentCard_Rating__10-Hu :last-child{
    font-weight:700;
    color: #4A90E2;
}

.StudentCard_SkillsRow__ugSKu h4 {
    font-size: 14px;
    color:#9196A5;
    font-weight: 400;

}
.StudentCard_SkillList__3v9YU{
    list-style: none;
    display: flex;
    margin-top: 8px;
}

.StudentCard_SkillList__3v9YU li 
{
    
    background: #FFD666;
    border-radius: 4px;
    padding: 3px 11px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #E09320;
    margin: 0px 5px;
}

.StudentCard_SkillList__3v9YU :first-child{
    margin-left: 0;
}

.StudentCard_SkillList__3v9YU :last-child{
    margin-right: 0;
}

.StudentCard_Loading__3trgP .StudentCard_StudentImage__3pgJv{
    background-color: #e7e7e7;
    /* object-fit: none; */
}

.StudentCard_Loading__3trgP .StudentCard_StudentName__LQ3f5{
    background-color: #e7e7e7;
    color: transparent;
    /* width: 80%; */
    border-radius: 5px;
    height: 28px;
}

.StudentCard_Loading__3trgP Button {
    display: none;
}

.StudentCard_Loading__3trgP .StudentCard_StudentData__joLsp {
    min-width: 60%;
}

.StudentCard_Loading__3trgP .StudentCard_StudentSubTitle__3agvc{
    background-color: #e7e7e7;
    border-radius: 5px;
    margin-top:11px;
    width: 50%;
    height: 17px;
    content-visibility: hidden;
}

.StudentCard_excellent__MEzsk{
    float:right;
    margin-right: -3px;
}

/* .GOOD{
    height: 23px;
    background: #9196A5;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    float:right;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-align: center;
    vertical-align: center;
    padding: 7px 10px 0 10px;   
}

.AVERAGE{
    border: 1px solid #9196A5;
    height: 23px;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    float:right;
    letter-spacing: -0.01em;
    color: #9196A5;
    text-align: center;
    vertical-align: center;
    padding: 7px 10px 0 10px;   
}

.NEW{
    height: 23px;
    background: #FF5670;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    float:right;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-align: center;
    vertical-align: center;
    padding: 7px 10px 0 10px;
} */
*{
  margin: 0;
  padding: 0;
}

img{
  overflow: hidden;
}

hr{
  height: 0px;
  border: none;
  border-bottom: 1px solid #D3D5DB;
}


.top-bar{

  height: 59px;
  background: #17203A;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 14px 14px 0px 0px;
}

.cross-button{
  position: relative;
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 20px;
  /* left: 31px; */
  margin-top:20px;
  color: #232D4C;
  background-color: #FF5670;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
}

.x-button{
  margin-left: 0.8px;
  width: 8px;
}

.resume-container{
  /* width: 886px; */
  /* height: 400px; */
  word-break: break-word;
  padding-bottom: 1px;
  background-color: #FFFFFF;
  box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
  border-radius:  0px 0px 14px 14px;
  /* text-transform: capitalize; */
}

.profilepic{
  width: 160px;
  height: 154px;
  box-shadow: 0px 22px 20px -13px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 40px;
}

.profileimg{
  display: block;
  width: 160px;
  height: 154px;
  object-fit: cover;
  object-fit: center;
}

.applicant-info{
  width: 100%;
}

.profile-container{
  display: flex;
  margin: 0px 40px;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 50px;
}

.applicant-name{

  font-weight: bold;
  font-size: 28px;
  color: #232D4C;
}

.applicant-details{
  margin-top: -4px;
  color: #232D4C;
}
.applicant-details span{
  background-color: #ccc;
  /* color: white; */
}

.applicant-bio{
  margin-top: 10px;
  color: #9196A5;
  line-height: 1.3em;
  font-weight: 300;
  
}

.rating-box{
  width: 163px;
  height: 50px;
  background: #F4F4F6;
  border-radius: 10px;
  text-align: center;
  display:inline-block;

}

.rating-text{
  /* font-family: SF Pro Display; */
  position: relative;
  top:10px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #4A90E2;
  /* margin-top: 13.5px; */
}


.next-button-container{
  position: absolute;
    top: 0;
    right: -27px;
    height: 100%;
    min-width: 10px;
}

.download-button-container{
  position: absolute;
  /* top: 0; */
  height: 100%;
  /* cursor:pointer; */
  right: -40px;
  min-width: 10px;
}

.prev-button-container{
  position: absolute;
    top: 0;
    left: -27px;
    height: 100%;
    min-width: 10px;
}

.next-button{
  position: -webkit-sticky;
  position: sticky;
  width: 54px;
  height: 54px;
  top: 40%;
  background: #27272D;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 7px 23px rgb(0 0 0 / 25%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-button{
  position: -webkit-sticky;
  position: sticky;
  width: 54px;
  height: 54px;
  top: 40%;
  background: #27272D;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 7px 23px rgb(0 0 0 / 25%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadButton{
  position: -webkit-sticky;
  position: sticky;
  width: 80px;
  height: 80px;
  top: 80%;
  /* background: #27272D; */
  /* border: 1px solid #FFFFFF; */
  /* box-shadow: 0px 7px 23px rgb(0 0 0 / 25%); */
  border-radius: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  cursor:pointer;
  z-index: 2;
}

.prev-button img {
  transform: rotate(180deg);
  flex-grow: 0;
}

.skills-heading{
  /* font-family: SF Pro Display; */
  font-size: 16px;
  color: #9196A5;
  margin-bottom: 5px;
}

.skills-section{
  margin:50px 40px;
  margin-top: 0px;
}


.skillBox{
  border-radius: 5px;
  display: inline-block;
  padding: 7px 18px;
  font-weight: 300;
  flex-shrink: 0;
}

.skillText{
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  word-break: break-word;
}

.skills-container{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px 5px;
  gap: 10px 5px;
}
.compact-skills{
  height: 34px;
  display: flex;
  grid-gap:5px;
  gap:5px;
  flex-wrap: wrap;
  overflow: hidden;
}

.section-section{
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.section-container{
  padding: 50px 60px 60px;
}

.section-title{
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18.5px;
  letter-spacing: -0.01em;
  color: #9196A5;
  padding-bottom: 5px;
}

.project{
  display: inline-block;
  vertical-align: top;
  /* padding: 5px 15px 15px 0; */
}

.experience-pic {
  /* width: 35px;
  height: 35px; */  /* opacity: 0.2; */
  border-radius: 4px;
  display: inline-block;
  align-items: flex-start;
  padding: 8px;
}

.project-name-flexbox{
  display:flex;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
}

.project-name{
  position: relative;
  top: -2px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  /* word-break: break-word; */
  /* word-break: break-all; */
}

.project-title{
/* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.5px;
  color: #000000;
  /* max-width: 230px; */
  overflow-wrap: break-word;
  /* word-break: break-all; */
}

.project-date{

  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #969BA9;
  text-transform: capitalize;
  margin-top: 3px;
  /* overflow-wrap: break-word; */
  /* word-break: break-all; */
}

.project-description{
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  color: #9196A5;
  font-weight: 300;
  /* overflow-wrap: break-word; */
  /* word-break: break-all; */

}

.visit-link-button{
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  margin-left: 14px;
  padding: 6px 10px;
  flex-shrink: 0;
}

.title-date-div{
  display: inline-block;
  vertical-align: top;
  padding-top:2px;
}

.visit-text{

display: block;
font-size: 14px;
letter-spacing: -0.01em;
color: #4A90E2;
line-height: 1em;
}

.gold{
  background-color: #ffef99;
  color: #E09320;
}

.silver{
  background-color: #ececed;
  color: #898989;
}

.bronze{
  background-color: #e7d2c1;
  color: #AF6E38;
}

.no-level{
  border: 1px solid #D4D5DA;
  color: #000;
}

.loading{

  height: 27px;
  width: 83px;
  background-color: #e7e7e7;
}

.tagRemoverButton{
  margin-left: 13px;
}

.project-pic{
  width:25px;
}

/* Print Stuff */

.contact-container{
  padding-top:8px;
  display:none;
}

.nobreakOnPrint{
  page-break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}

.dontBreakAfter{
  page-break-after: avoid;
  page-break-after: avoid;
  -webkit-column-break-after: avoid;
          break-after: avoid;
}

.dontBreakBefore{
  page-break-before: avoid;
  page-break-before: avoid;
  -webkit-column-break-before: avoid;
          break-before: avoid;
}

.status{
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-width: 200px;
}
.status.Hired,
.status-message.Hired{
 color:#57a3c8;
 background-color:#e6f3fb;
}

.status.Rejected,
.status-message.Rejected
{
  color:#ff4a4a;
  background-color:#ffeeef;
 }

 .status-message{
   text-transform: none;
   align-items: center;
   font-size: 16px;
   color:#444;
   background: #f5f5f5;
   padding: 20px 30px;
   text-align: center;
   border-radius: 5px;
 }

.side-button-enter .button{
  opacity: 0;
  transform: scale(0.7);
}
.side-button-enter-active .button{
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.1s ease-in-out, trasform 0.1s ease-in-out;
}

.side-button-exit .button,
.side-button-exit:hover .button{
  opacity: 1;
  transform: scale(1);
}
.side-button-exit-active .button,
.side-button-exit-active:hover .button{
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.1s ease-in-out , transform 0.1s ease-in-out;
}

/* Loading Styles */

.profile-container.Loading .applicant-name::after,
.profile-container.Loading .applicant-details::after,
.profile-container.Loading .applicant-bio::after,
.project.Loading .experience-pic::after,
.project.Loading .project-title::after,
.project.Loading .project-date:after,
.project.Loading .project-description:after
{
  position: absolute;
  top:0;
  left:0;
  content: "";
  border-radius: 5px;
  display: block;
  height: 80%;
  width: 50%;
  background: #e5e5e5;
}
.profile-container.Loading .applicant-details::after,
.project.Loading .project-date:after{
  width: 30%;
  height: 60%;
}
.profile-container.Loading .applicant-bio::after{
  width: 100%;
  height: 80%;
}

.project.Loading .experience-pic:after,
.project.Loading .project-description::after,
.project.Loading .project-title::after
{
  width: 100%;
  height: 100%; 
}

.profile-container.Loading .applicant-name,
.profile-container.Loading .applicant-details,
.profile-container.Loading .applicant-bio,
.project.Loading .experience-pic,
.project.Loading .project-title,
.project.Loading .project-date,
.project.Loading .project-description
{
  position: relative;
  color:transparent;
}

/* .project.Loading .experience-pic{


} */

@media print {
  .Print-Titlecase{
    text-transform: capitalize;
  }
  .contact-container{
    display: block;
  }

  .applicant-details span{
    background-color: transparent;
  }

  .hideOnPrint, .top-bar, .candididateTaggerDiv {
    display:none;
  }

  .resume-container{
    box-shadow: none
  }

  .section-title{
    font-size: 18px;
    color:#4A90E2;
    /* font-weight: 600; */
  }

  .project-pic{
    width: 15px;
  }

  .project-title, .project-date, .skillText{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000
  }

  .project-title{
    font-weight: 500;
    font-size: 14px;
  }

  .project-description{
    font-size: 10px;
    line-height: 11px;
    color: #000;
  }

  /* .skillText{
    font-size: 12px;
  } */

  .section-container{
    padding-top: 0;
    padding-bottom: 40px;
  }

  .applicant-bio{
    margin:0
  }

  .section-section{
    /* grid-gap: 20px 10px; */
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
  }

  .skillBox{
    padding: 7px 12px;
  }
}
.StudentList_StudentListContainer__3DCGr
{
    background: #F4F4F6;
    padding: 64px;
    padding-top:84px;
}

.StudentList_StudentList__3c5hY{
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}
.Loader_lds-ring__3XAb5 {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin:auto;
  }
  .Loader_lds-ring__3XAb5 div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: Loader_lds-ring__3XAb5 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #232d4c transparent transparent transparent;
  }
  .Loader_lds-ring__3XAb5 div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .Loader_lds-ring__3XAb5 div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .Loader_lds-ring__3XAb5 div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes Loader_lds-ring__3XAb5 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.Modal_Overlay__34vcz{
    z-index: 100;
    position: fixed;
    top:0;
    left: 0;
    right:0;
    bottom: 0;
    background: #232D4C;
    opacity: 0.3;
}

.Modal_Modal__3df1B{
  
    z-index: 110;
    /* position: fixed; */
    position: absolute;
    top: 50%;
    background: white;
    border-radius: 10px;
    width: 80%;
    max-width: 900px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Modal_ModalContainer__2f33w{
    top:0;
    left:0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

/* .NewScrollBar::-webkit-scrollbar {
    width: 7px;
    height: 7px;
   }
.NewScrollBar::-webkit-scrollbar-thumb {
background: linear-gradient(13deg, #f9d4ff 14%,#c7ceff 64%);
border-radius: 10px;
}
.NewScrollBar::-webkit-scrollbar-thumb:hover{
background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%);
}
.NewScrollBar::-webkit-scrollbar-track{
background: #ffffff;
border-radius: 15px;
box-shadow: inset 17px 0px 0px #f0f0f0;
} */
.CandidateTagger_outercontainer__isigL{
    width:100%;
    padding: 20px 31px 0px 31px;
    display:grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
}

.CandidateTagger_innercontainer__3jNF5{
    background: #F4F4F6;
    padding-top:10px;
    padding-left: 13px; 
    padding-right: 13px;
    
    border-radius: 10px;
    vertical-align: top;
}

.CandidateTagger_contactContainer__1-FDA{
    vertical-align: top;
    /* height: 108px; */
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px 26px 22px 26px;
    /* display:inline-block; */
    /* margin-left: 30px; */
    /* width:376px; */
    /* width: 50%; */
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.CandidateTagger_contactContainer__1-FDA.CandidateTagger_Loading__37i8q{
    background: #f4f4f4;
    color:transparent;
    border:none;
}

.CandidateTagger_contactContainer__1-FDA.CandidateTagger_Loading__37i8q img,
.CandidateTagger_contactContainer__1-FDA.CandidateTagger_Loading__37i8q .CandidateTagger_number__3reSU{
    opacity: 0;
}

.CandidateTagger_candidatetaggertext__aATOh{
    height: 17px;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.CandidateTagger_numberdiv__KVmuM{
    padding-top:8px;
    vertical-align: top;

}

.CandidateTagger_number__3reSU{
    height: 19px;
    font-size: 16px;
    line-height: 19px;
    color: #9196A5;
    display:inline-block;
    vertical-align: top;
    padding-top:-4px;
    padding-left: 11px;
}




.CandidateTagger_spantext__CX2_d{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4A90E2;
}

.CandidateTagger_buttoncontainer__2UvNV{
    padding:12px 0 22px 0; 
    display: flex;
    flex-direction: row;
    grid-gap:16px;
    gap:16px;
}

.CandidateTagger_printOnly__27fTp{
    display: none;
}




@media print {
    .CandidateTagger_innercontainer__3jNF5, .CandidateTagger_contactContainer__1-FDA{
      display:none;
    }
    .CandidateTagger_printOnly__27fTp{
        display: block;
        text-transform: lowercase;
    }
    .CandidateTagger_outercontainer__isigL{
        grid-template-columns: 1fr;
        text-align: right;
    }
    .CandidateTagger_numberDiv__2RVil{
        padding-top:0;
    }
  }

.ProfilePicture_ProfilePictureContainer__21n-I{
    width: 100%;
    height: 100%;
    position: relative;
}

.ProfilePicture_ProfilePictureContainer__21n-I img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProfilePicture_ProfilePictureContainer__21n-I .ProfilePicture_ProfilePicture__2DZPT{
    position: absolute;
    top:0;
    left:0;
    transition: opacity 0.2s ease-in-out;
}
.filter-container{
  background: #FFFFFF;
  box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 17px 31px 24px 31px;
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #232D4C;
  /* display: inline-block; */
  box-sizing: border-box;
  width: 100%;
}

.searchTagInput{
  padding-top: 2px;
  padding-left: 10px;
  font:18x;
  display:inline-block;
  border: 0px;
  display: flex;
  color: #4A90E2;
  min-width: 40%;
  vertical-align: middle;
}

.close-filters-button{
  /* display: inline-block; */
  float: right;
  position: relative;
  /* left: 2px; */
  padding:5px;
  cursor:pointer;
}

.filter-tag-container{
  /* display: inline-block; */
  /* margin-top: 17px; */
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #232D4C;
  /* width: 50%; */
  display: inline-flex;
  box-sizing: border-box;
  flex-direction: column;
}

/* .filter-tag-container:nth-of-type(even){
 padding-left:20px; 
}

.filter-tag-container:nth-of-type(odd){
  padding-right:20px; 
 } */


.filter-input-field{
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  padding: 11px 20px 12px 16px;
  margin: 8px 0 3px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4A90E2;
}

.filter-gridBox{
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:17px 40px;
  margin-top:17px;
}

.filter-input-field-multi{
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #4A90E2;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  padding: 11px 20px 0 7px;
  margin: 8px 0 3px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4A90E2;
  min-height: 40px;
}

/* .noHighlight:focus-visible{
  display:none;
} */

.searchTagInput:focus{
  outline:none;
}

.noHightlight{
  padding-top: 2px;
  padding-left: 10px;
  font:18x;
  display:inline-block;
  border: none;
  display: flex;
  color: #4A90E2;
  width: 40%;
}

.apply-filters-button{
  width: 129px;
  height: 50px;
  background: #4A90E2;
  border-radius: 5px;
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.apply-filter-button-div{
  text-align: center;
  margin-top: 25px;
}

.SkillInput_SkillTextInput__1qI4m{
    padding: 15px 11px;
    width: 100%;
    border:none;
    outline: none;
}

.SkillInput_SkillInput__1vIbN{
    display: flex;
    border: 1px solid #9196A5;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
}

.SkillInput_AddBtn__Do8V5{
    padding:9px;
    background: #D3D5DB;
    border-radius: 5px;
    margin-right: 7px;
}

.SkillInput_AddBtn__Do8V5.SkillInput_Active__2nUFE{
    cursor: pointer;
    background: #4A90E2;
}

.SkillInput_AddBtn__Do8V5 img{
    display: block;
}

.SkillInput_SkillList__Wl3ON{
    display: flex;
    flex-wrap: wrap;
    grid-gap:5px;
    gap:5px;
    margin: 8px 0;
}
.Skill_Skill__1mFZF{
    background: #D3D5DB;
    padding: 4px 9px 4px 11px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    display: inline-flex;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.Skill_RemoveBtn__2YF17 img{
    display: block;
}

.Skill_RemoveBtn__2YF17{
    margin-left: 7px;
    cursor: pointer;
    outline: none;
}
.AppLoader_LoadingLogo__2qAeV {
    line-height: 1;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
  
    transform: translate(-50%, -50%);
    animation: AppLoader_bubbling__FbVUp 1s ease-in-out alternate-reverse infinite;
  }
  
  .AppLoader_LoadingLogo__2qAeV h1 {
    margin: 0;
    font-size: 92px;
    font-weight: 900;
  }
  
  @keyframes AppLoader_bubbling__FbVUp {
    from {
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
  
.SuccessModal_title__18RXc{
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    color: #232D4C;
    padding:5px;
}

.SuccessModal_container__2jkRe{
    text-align: center;
    margin-top:-20px;
}

.SuccessModal_subtitle__HC6Cl{
    height: 19px;
    font-size: 14px;
    line-height: 135.2%;
    text-align: center;
    color: #000000;
    /* padding-top: 14px; */
    padding: 14px 20px 14px 20px;
}

.SuccessModal_button__1XYA0{
    width: 281px;
    height: 64px;
    background: #4A90E2;
    border-radius: 10px;
    color:white;
    margin-top:38px;
}
.SuccessModal2_CloseBtn__1voCi{
    width: 100%;
    padding:15px;
    cursor: pointer;
    border-top: 1px solid #e3e2e2;
    margin-top:30px;
    font-family: 'poppins';
    outline: none;
}

.SuccessModal2_CloseBtn__1voCi:active{
    background-color: #eee;   
}

.SuccessModal2_Container__27VJx{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.SuccessModal2_Tick__177rA{
    display: block;
    height: 90px;
    margin:auto;
    margin-top:45px;
}

.SuccessModal2_Title__2RTHh{
    font-weight: bold;
    margin-top:11px; 
    margin-bottom: 4px;
    font-size: 20px;
    text-align: center;
    color:#232D4C;
}

.SuccessModal2_Subtitle__87-UT{
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.191177px;
    color: #969BA9;
}
.Toasts_Toast__X0QWy{
    /* position: fixed;
    right: 40px;
    bottom: 20px; */
    padding: 18px 40px;
    background-color: #44D5BA;
    color: #fff;
    border-radius: 10px;
    padding-right: 0px;
    transition: opacity 1s ease-in-out;
}

.Toasts_Cross__1INBE{
    height: 10px;
}

.Toasts_CrossBtn__Q4s_c{
    margin: -30px 0;
    border: none;
    display: inline-flex;
    padding: 20px 20px;
    margin-left: 3px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    outline: none;
}

.Toasts_Toast__X0QWy:hover .Toasts_CrossBtn__Q4s_c{
    opacity: 1;
}


.Toasts_ToastContainer__kmTUD {
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 12px;
    gap: 12px;
    z-index: 200;
}

.Toasts_ToastEnter__14ask{
    opacity: 0;
    transform:translateX(0%) scale(0.7);
}

.Toasts_ToastEnterActive__I-WVA{
    opacity: 1;
    transform: translateX(0) scale(1);
    transition: transform 0.3s ease-out, opacity 0.3s ease-in-out;
}

.Toasts_ToastExit__3bZfM {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
.Toasts_ToastExitActive__245ZH {
    opacity: 0;
    transform: translateX(0) scale(0.7);
    transition: transform 0.2s ease-out, opacity 0.2s ease-in-out;
  }
.Nav_Nav__A-APu{
    display: flex;
    flex-direction: column;
    margin-left:-40px;
}

.Nav_Nav__A-APu a {
    /* font-size: 14px; */
    /* font-weight: 300; */
    padding : 10px 0px;
    padding-left: 40px;
    transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
    border-radius: 0px 5px 5px 0px;
    color: #777;
    margin-bottom: 5px;
}


.Nav_Nav__A-APu a.Nav_ActiveLink__2LtLq {
    background-color: #4A90E2;
    color: white;
}

.Nav_Nav__A-APu a:hover{
    background-color: #ddd;
}



a.Nav_ActiveLink__2LtLq:hover{
    /* background-color: #0D9AE0; */
    transform-origin: left;
    background-color: #4A90E2;
}
.Layout_SideBar__nBjG0{
    background: #c4c4c419;
    padding: 70px 40px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: space-between;
}

.Layout_Container___qX_b{
    background: radial-gradient(166.67% 269.75% at 85.98% 167.57%, #DCE8F6 0%, #FFFFFF 100%);
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
}

.Layout_Main__169zC {
    overflow-y: auto;
    padding: 40px;
}

.Layout_Header__3blkC{
    text-align: center;
}

.Layout_Email__2mi5Y{
    color:#797979;
}

.Layout_Logout__2AUJG{
    border-radius:0;
    border:none;
    text-align: left;
    padding: 10px 20px;
    padding-left: 40px;
    margin-left: -40px;
    width: unset;
    background-color: unset;
    border-radius: 0 3px 3px 0;
    display: flex;
    align-items: center;
    opacity: 0.5;
    color: rgba(0, 0, 0);
    transition: opacity 0.1s ease-in-out;
}

.Layout_Logout__2AUJG:hover{
    box-shadow: none;
    background: #ddd; 
    opacity: 0.7;
    transform: none;
}

.Layout_LogoutIcon__2WGif{
    margin-left: 10px;
    opacity: 0.9;
    height: 18px;
}
.Students_Content__1Xrdx{
    margin-top:20px;
}


.SearchInput_InputContainer__2-Lch{
    display: inline-flex;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
}

.SearchInput_Input__2-c6t{
    font-size: 16px;
    border:none;
    padding: 11px 16px;
    min-width: 400px;
}

.SearchInput_Input__2-c6t:focus,
.SearchInput_Button__3ynkF:focus{
    outline: none;
}


.SearchInput_InputContainer__2-Lch:focus-within{
    border-color: #4A90E2;;
}

.SearchInput_Button__3ynkF{
    border: none;
    background: #4A90E2;
    border-radius: 0px 5px 5px 0px;
    color: white;
    padding: 16px;
}

.JobsContainer_Content__2ztpP{
    margin-top:20px;
}


.JobsTable_Table__34W_P {
	margin: 20px 0;
	background: white;
	padding: 8px 16px;
	border-radius: 5px;
}

.JobsTable_Row__WqApc {
	padding: 10px;
	border-bottom: 1px solid #ededed;
	display: grid;
	grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
	color: #9c9c9c;
}

.JobsTable_TableContent__18QRp :last-child {
	border: none;
}

.JobsTable_JobRow__2C67P {
}

.JobsTable_TableHeaderRow__1K3Go {
	font-weight: 500;
	color: #9c9c9c;
}

.JobsTable_Cell__Gkd5F {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.JobsTable_NameCell__17_vY {
	cursor: pointer;
}

.JobsTable_NameCell__17_vY .JobsTable_Title__3auLE {
	font-weight: 500;
	color: #4a90e2;
}

.JobsTable_NameCell__17_vY .JobsTable_UID__3J1q6 {
	color: #aaa;
	font-weight: 300;
	font-size: 12px;
	line-height: 12px;
}

.JobsTable_NameCell__17_vY .JobsTable_Subtitle__3Caws {
	color: #a09b9b;
	font-size: 14px;
}

.JobsTable_DeadlineCell__2IqAs .JobsTable_Title__3auLE {
	font-size: 14px;
	font-weight: 500;
}

.JobsTable_DeadlineCell__2IqAs .JobsTable_Subtitle__3Caws {
	font-size: 14px;
}

.JobsTable_CenterCell__2XxP6 {
	align-items: center;
}

.JobsTable_Tag__mbRsS {
	border-radius: 5px;
	font-size: 12px;
	min-width: 50px;
	padding: 2px 5px;
	text-align: center;
}

.JobsTable_Tag__mbRsS.JobsTable_Active__WeQdM,
.JobsTable_Tag__mbRsS.JobsTable_Open__3PtOE {
	color: #4a90e2;
	background: #e2edfa;
}

.JobsTable_Tag__mbRsS .JobsTable_Success__r0JEG,
.JobsTable_Tag__mbRsS.JobsTable_Live__Wnm42 {
	color: #5ec2b0;
	background: #cdf4ec;
}

.JobsTable_Tag__mbRsS.JobsTable_Pending__U9wnF {
	background-color: #f9eab0;
	color: #e09320;
}

.JobsTable_Tag__mbRsS.JobsTable_Partial__2hDKA {
	background-color: #feffd2;
	color: #ffd600;
}

.JobsTable_StatusCell__6G76O .JobsTable_Inactive__32-jO,
.JobsTable_Tag__mbRsS.JobsTable_Partial__2hDKA {
	color: #ababab;
	background: #efefef;
	border-radius: 5px;
	font-size: 12px;
	min-width: 50px;
	text-align: center;
	padding: 2px 5px;
}

.JobsTable_JobRow__2C67P .JobsTable_ActionCell__2tXea {
	opacity: 0;
}

.JobsTable_JobRow__2C67P:hover .JobsTable_ActionCell__2tXea {
	opacity: 1;
}

.JobsTable_Loadmore__297AI {
	text-align: center;
	padding: 10px;
	font-size: 14px;
	background: white;
	border-radius: 3px;
	color: #4a90e2;
	cursor: pointer;
}

.JobsTable_Loadmore__297AI:hover {
	background-color: #eee;
}

.JobsTable_Loadmore__297AI:active {
	background-color: #ddd;
}

.KebabMenu_Kebab__37BGH{
    display: flex;
    grid-gap: 0.3em;
    gap: 0.3em;
    flex-direction: column;
    padding: 6px 15px;
    cursor: pointer;
    border: none;
    background: transparent;
}

.KebabMenu_Kebab__37BGH > div{
    width: 0.3em;
    height: 0.3em;
    background-color: #ddd;
    border-radius: 50%;
}

.KebabMenu_Kebab__37BGH:hover > div{
    background-color: #aaa;
}


.KebabMenu_KebabContainer__3DL3J{
    position: relative;
}


.KebabMenu_DropdownMenu__1Epw5{
    position: absolute;
    right: 0;
    top: 100%;
    /* width: 100%; */
    width: 200px;
    /* font-size: 0.9em; */
    z-index: 20;
}
.KebabMenu_DropdownMenu__1Epw5::before{
    box-shadow: 0px 4px 15px 0px #0000002b;
    content: "";
    top:0;
    left:0;
    background: white;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #0000001f;
    border-radius: 10px;
}

.KebabMenu_DropdownMenuContent__1vpvY{
    position: relative;
    width: 100%;
    height: 100%;
    left:1px;
    top:1px;
    border-radius: 10px;
    overflow: hidden;
}

.KebabMenu_DropdownMenuEnter__2bcoU::before{
    transform-origin: top;
    transform:scaleY(0.5);
    opacity: 0;
}
.KebabMenu_DropdownMenuEnterActive__3U0Rq::before{
    transform:scaleY(1);
    opacity: 1;
    transition: transform .1s ease-out, opacity 0.1s ease-in-out ;
}

.KebabMenu_DropdownMenuEnter__2bcoU .KebabMenu_DropdownMenuContent__1vpvY{
    opacity: 0;
}
.KebabMenu_DropdownMenuEnterActive__3U0Rq .KebabMenu_DropdownMenuContent__1vpvY{
    opacity: 1;
    transition: opacity .05s ease-in-out;
}



.KebabMenu_DropdownMenuExit__2s30v::before{
    transform-origin: top;
    transform:scaleY(1);
    opacity: 1;
}
.KebabMenu_DropdownMenuExitActive__yH_eK::before{
    transform:scaleY(0.5);
    opacity: 0;
    transition: transform .1s ease-out 0.05s, opacity 0.1s ease-in-out 0.05s ;
}

.KebabMenu_DropdownMenuExit__2s30v .KebabMenu_DropdownMenuContent__1vpvY{
    opacity: 1;
}
.KebabMenu_DropdownMenuExitActive__yH_eK .KebabMenu_DropdownMenuContent__1vpvY{
    opacity: 0;
    transition: opacity .1s ease-in-out;
}


.KebabMenu_Option__GG1Zy{
    font-family: 'Poppins';
    color: grey;
    padding:12px 15px;
    cursor: pointer;
    display: flex;
    grid-gap:10px;
    gap:10px;
    background: none;
    border: none;
    width: 100%;
    align-items: center;
    transition: background-color 0.05s ease-in-out;
}

.KebabMenu_Option__GG1Zy:hover{
    background: #eee;
}

.KebabMenu_Option__GG1Zy:active{
    background: #ddd;
}
.HomeInfo_container__eZQgT{
    background: #FFFFFF;
    box-shadow: 0px 26px 24px -20px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    text-align: left;
    padding-bottom: 25px;
    max-height: 600px;
    overflow-y: auto;
}

.HomeInfo_header__3JhWl{
    background: linear-gradient(203.68deg, #E071F2 6.87%, #FE8999 98.3%);
    box-sizing: border-box;
    border-radius: 14px;
    height: 136px;
    padding: 18px;
    background-image: url(/static/media/headerBackgroundimage.4a8a599a.svg);
    text-align: left;
    vertical-align: middle;
}

.HomeInfo_headerInner__KKU8y{
    background: rgba(255, 255, 255, 0.45);
    -webkit-backdrop-filter: blur(44px);
            backdrop-filter: blur(44px);
    border-radius: 8px;
    padding: 19px 22px 25px 17px;
    text-align: left;
    vertical-align: middle;
    display:grid;
    grid-template-columns: 1fr auto;
}

.HomeInfo_buttonsContainer__3jerf{
    text-align:center;
    vertical-align:middle;
    display: grid;
    grid-gap:16px;
    /* grid-template-columns:150px 1fr; */
    /* min-width:310px; */
    float:right;
}

.HomeInfo_title__tDO1N{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    max-height: 33px;
    overflow:hidden;
    color: #232D4C;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.HomeInfo_titleBox__1FUps{
    display:flex;
    max-width: 370px;
    overflow:hidden;
    flex-direction: column;
}

.HomeInfo_desc__1iKaL{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    margin-top: 4px;
    text-transform: capitalize;
    max-height: 34px;
    overflow:hidden;
}    

.HomeInfo_appliedBox__27dnn{
    padding: 9px 14px 9px 11px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 12px;
    /* float: right; */
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0075FF;
    vertical-align:middle;
    cursor:pointer;
}

.HomeInfo_deletejobBox__2Wi6g{
    padding: 9px 28px 9px 28px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 12px;
    /* float: right; */
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FF5670;
    vertical-align:middle;
    margin-left:16px;
    cursor:pointer;
}

.HomeInfo_secondaryBox__1jLrv{
    padding: 16px 18px 18px 18px;
}

.HomeInfo_categoryBox__3PSa4{
    max-width: 804px;
    /* height: 69px; */
    background: #F4F4F6;
    border-radius: 8px;
    padding: 14px 16px 14px 16px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.HomeInfo_catTitle__ONgCC{
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    opacity: 0.5;
    margin-bottom: 4px;
}

.HomeInfo_catBox__1Y4ao{
    margin-right: 57px;
    /* display:inline-block; */
}

.HomeInfo_cat__tpxQU{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    margin-top: 4px;
}

.HomeInfo_thirdBox__D8Xti{
    text-align: left;
    padding-left: 18px;
    padding-right: 18px;
    vertical-align: top;
    display:grid;
    grid-template-columns: 393px 1fr;
}

.HomeInfo_jobdesc__3UH8o{
    min-height: 221px;
    vertical-align:top;
    padding-left: 18px;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    word-break: break-word;
}

.HomeInfo_descpara__29KeY{
    font-size: 14px;
    line-height: 17px;
    word-break: break-word;
    color: #232D4C;
    padding-right: 20px;
}

.HomeInfo_list__19Fin{
    padding-left: 18px;
    padding-top: 4px;
}

.HomeInfo_schedule__1iT58{
    padding-top: 18px;

}

.HomeInfo_Qualifications__1YQ2e{
    margin-top:20px;
    padding: 0 20px;
    margin-bottom: 20px;
}

.HomeInfo_Qual__3zYt2{
    margin-bottom: 10px;
    text-transform: capitalize;
}
.HomeInfo_College__Po-EJ{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
}

.HomeInfo_CourseList__20kzB{
    font-size: 14px;
    line-height: 140.2%;
}

.HomeInfo_Recipients__-sF3I{
    display: grid;
    grid-template-columns: 1fr auto auto;
    justify-content: space-between;
    padding: 3px 0;
    grid-gap: 10px;
    gap: 10px;
    text-transform: capitalize;
    align-items: center;
}

.HomeInfo_Recipients__-sF3I:hover{
    background: #eee;
    
}

.HomeInfo_Status__1YROw{
    font-size: 14px;
    color: #666;
    font-weight: 300;
}

.HomeInfo_Status__1YROw::before{
    content: "";
    width: 10px;
    height: 10px;
    color:red;
    display: inline-block;
    border-radius: 5px;
}

.HomeInfo_Status__1YROw.HomeInfo_pending__17cOy::before{
    background-color:#ffdc65;
}

.HomeInfo_Status__1YROw.HomeInfo_live__3HM5Q::before{
    background-color: #81ff4f;
}

.HomeInfo_Status__1YROw.HomeInfo_rejected__2c4dN::before{
    background-color: #ffa6a6;
}
.minimumqualifications_container__2lFkQ{
    width: 393px;
    min-height: 221px;
    background: #FFFFFF;
    border: 1px solid #D3D5DB;
    box-sizing: border-box;
    border-radius: 8px;
    display:inline-block;
    padding: 16px 16px 16px 16px;
    align-self: flex-start;
    /* text-align: center; */
    align-items: center;
    max-height: 600px;
    overflow-y: auto;
}

.minimumqualifications_Title__gWuO0{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
    padding-bottom: 14px;
}

.minimumqualifications_gridBox__1uh6i{
    display:grid;
    vertical-align: flex-start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    padding-bottom: 30px;
}

/* .qualificationcontainer{
    width:50%;
    display:inline-block;
    padding-bottom: 40px;
} */

.minimumqualifications_degreeTitle__AAQ4W{
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #9196A5;
    text-transform: capitalize;
}

.minimumqualifications_degreeTitleCap__3YQoE{
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #9196A5;
    text-transform: uppercase;
}

.minimumqualifications_percent__3KwAk{
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: #4A90E2;
}

.ChangeDeadline_Save__2SZqS{
    width: 150px;
    margin: auto;
    display: block;
    padding: 15px;
    margin-top: 20px;
}

.ChangeDeadline_Form__3TRES{
    padding: 40px;
    padding-top: 20px;

}

.ChangeDeadline_FromNow__3Ruo0{
    float: right;
    color: #4a90e2;
    font-size: 14px;
}

.ChangeDeadline_DeadlineLabel__3nByY{
    justify-content: space-between;
}
.CompaniesContainer_Content__2mMVA{
    margin-top:20px;
}


.CompaniesTable_Table__1du1o {
    margin: 20px 0;
    background: white;
    padding: 8px 16px;
    border-radius: 5px;
}

.CompaniesTable_Row__1-KPc{
    padding: 10px;
    border-bottom: 1px solid #EDEDED;;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr  1fr;
    color: #9C9C9C;
}

.CompaniesTable_TableContent__1FuTO :last-child{
    border: none;
}

.CompaniesTable_JobRow__2C3qp{

}



.CompaniesTable_TableHeaderRow__10gAm{
    font-weight: 500;
    color: #9C9C9C;
}

.CompaniesTable_Cell__3Rus2{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CompaniesTable_NameCell__2InD2{
    cursor: pointer;
}

.CompaniesTable_NameCell__2InD2 .CompaniesTable_Title__17wSt{
    font-weight: 500;
    color: #4A90E2;
}

.CompaniesTable_NameCell__2InD2 .CompaniesTable_Subtitle__dnb0c{
    color: #A09B9B;
    font-size: 14px;
}

.CompaniesTable_DeadlineCell__2Stcc .CompaniesTable_Title__17wSt{
    font-size: 14px;
    font-weight: 500;
}

.CompaniesTable_DeadlineCell__2Stcc .CompaniesTable_Subtitle__dnb0c{
    font-size: 14px;
}


.CompaniesTable_CenterCell__juopr{
    align-items: center;
}

.CompaniesTable_Tag__1FgD1 {
    border-radius: 5px;
    font-size: 12px;
    min-width: 50px;
    padding: 2px 5px ;
    text-align: center;
}

.CompaniesTable_Tag__1FgD1.CompaniesTable_Active__DQ_Tr,
.CompaniesTable_Tag__1FgD1.CompaniesTable_Open__3szAF
{
    color: #4A90E2;
    background: #E2EDFA;
   
}



.CompaniesTable_Tag__1FgD1 .CompaniesTable_Success__2ekj3,
.CompaniesTable_Tag__1FgD1.CompaniesTable_Verified__1SMFF{
    color: #5EC2B0;
    background: #CDF4EC;
}

.CompaniesTable_Tag__1FgD1.CompaniesTable_Pending__1WkfM{
    background-color: #F9EAB0;
    color: #E09320;
}

.CompaniesTable_Tag__1FgD1.CompaniesTable_Partial__3Vm99{
    background-color: #FEFFD2;
    color: #FFD600;
}

.CompaniesTable_StatusCell__2es5T .CompaniesTable_Inactive__t9EPg,
.CompaniesTable_Tag__1FgD1.CompaniesTable_Unverified__2vEY5
{
    color: #ABABAB;
    background: #EFEFEF;
    border-radius: 5px;
    font-size: 12px;
    min-width: 50px;
    text-align: center;
    padding: 2px 5px ;
}

.CompaniesTable_JobRow__2C3qp .CompaniesTable_ActionCell__1pnS9{
    opacity: 0;
}

.CompaniesTable_JobRow__2C3qp:hover .CompaniesTable_ActionCell__1pnS9{
    opacity: 1;
}

.CompaniesTable_Loadmore__3zXfN{
    text-align: center;
    padding: 10px;
    font-size: 14px;
    background: white;
    border-radius: 3px;
    color: #4a90e2;
    cursor: pointer;   
}

.CompaniesTable_Loadmore__3zXfN:hover{
    background-color: #eee;
}

.CompaniesTable_Loadmore__3zXfN:active{
    background-color: #ddd;
}


.CompanyContent_Container__2opHF{
    padding: 30px;
    min-height: 400px;
    word-wrap: break-word;
    max-height: 80vh;
    overflow-y: auto;
}

.CompanyContent_LogoContainer__33RCA{
    width: 130px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    overflow:hidden;
}

.CompanyContent_Logo__2I6Hp {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CompanyContent_Header__1cgez{
    display: grid;
    grid-template-columns: auto 1fr 150px;
    grid-gap: 40px;
    gap: 40px
}

.CompanyContent_Name__32DXs{
    font-size: 22px;
    font-weight: 700;
}



.CompanyContent_Field__1bEyS{

    margin-top: 17px;
}

.CompanyContent_Field__1bEyS .CompanyContent_Sub__2a-mF{
    font-size: 14px;
    color: #9C9C9C;
}

.CompanyContent_Field__1bEyS .CompanyContent_Title__3fc4f{

}
.Rating_Content__AXfO-{
    margin-top:20px;
}
.RatingForm_FormSection1__2byxS {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
    border-radius: 5px;
    padding: 25px 35px;
    padding-top: 15px;

}

.RatingForm_Form__1YKJi {
    max-width: 600px;
    margin: auto;
    margin-top: 40px;
}

.RatingForm_Button__2eU_n{
    max-width: 200px;
    display: block;
    margin:auto;
    margin-top: 20px;
    padding: 15px;
    font-size: 16px;
}
.SkillInput_Skill__3qlg9{
    background: #C4C4C4;
    border-radius: 5px;
    padding: 3px 7px;
    font-size: 12px;
    /* display: inline-block; */
    cursor: pointer;
    max-width: 100%;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}

.SkillInput_Skill__3qlg9 span{
    overflow-wrap: break-word;
    max-width: 100%;
    /* flex-basis: 100%; */
    display: block;
}

.SkillInput_SkillContainer__2T7FG{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
    margin: 10px auto ;

}

.SkillInput_Cross__3cmzD{
    -webkit-filter: invert(0.7);
            filter: invert(0.7);
}
.UploadInput_UploadInput__2SPVa{
    background: #FFFFFF;
    border: 2px dashed #E4E4E4;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 20px;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.UploadInput_UploadInput__2SPVa.UploadInput_Highlight__3aEtz{
    border-color: #4A90E2;
}

.UploadInput_FileInput__1fGKt{
    display: none;
}

.UploadInput_InputPlaceholder__1mBjP{
    color: #AEAEAE;
    font-size: 18px;
}

.UploadInput_Browse__1pB-V{
    color: #4A90E2;
    cursor: pointer;
}


.UploadInput_FileName__3-vHK{
    font-size: 14px;
    color: #4A90E2;
    font-weight: 300;
}
.Table {
    margin: 20px 0;
    background: white;
    padding: 8px 16px;
    border-radius: 5px;
}

.Row{
    padding: 10px;
    border-bottom: 1px solid #EDEDED;;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr  1fr;
    color: #9C9C9C;
}

.TableContent :last-child{
    border: none;
}

.JobRow{

}



.TableHeaderRow{
    font-weight: 500;
    color: #9C9C9C;
}

.Cell{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
}


.NameCell{
    cursor: pointer;
}

.NameCell .Title{
    font-weight: 500;
    color: #4A90E2;
    font-size: 16px;
}

.NameCell .Subtitle{
    color: #A09B9B;
    font-size: 14px;
}

.DeadlineCell .Title{
    font-size: 14px;
    font-weight: 500;
}

.DeadlineCell .Subtitle{
    font-size: 14px;
}


.CenterCell{
    align-items: center;
}

.Tag {
    border-radius: 5px;
    font-size: 12px;
    min-width: 50px;
    padding: 2px 5px ;
    text-align: center;
}

.Tag.Active,
.Tag.Open
{
    color: #4A90E2;
    background: #E2EDFA;
   
}



.Tag.Success,
.Tag.Verified{
    color: #5EC2B0;
    background: #CDF4EC;
}

.Tag.Pending{
    background-color: #F9EAB0;
    color: #E09320;
}

.Tag.Partial{
    background-color: #FEFFD2;
    color: #FFD600;
}

.StatusCell .Inactive,
.Tag.Unverified
{
    color: #ABABAB;
    background: #EFEFEF;
    border-radius: 5px;
    font-size: 12px;
    min-width: 50px;
    text-align: center;
    padding: 2px 5px ;
}

.JobRow .ActionCell{
    opacity: 0;
}

.JobRow:hover .ActionCell{
    opacity: 1;
}

.Loadmore{
    text-align: center;
    padding: 10px;
    font-size: 14px;
    background: white;
    border-radius: 3px;
    color: #4a90e2;
    cursor: pointer;   
}

.Loadmore:hover{
    background-color: #eee;
}

.Loadmore:active{
    background-color: #ddd;
}


.LogsTable_LogTableRow__29b9r{
    grid-template-columns: 2fr 2fr 2fr  3fr 1fr;
}

.LogsTable_Skills__1nlnr{
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    justify-content: flex-start;
    
}

.LogsTable_Skills__1nlnr span{
    background: #C4C4C4;
    border-radius: 5px;
    padding: 3px 7px;
    font-size: 12px;
    /* cursor: pointer; */
    color: #555;
}

.LogsTable_Logs__3MUlW{
    margin-top: 50px;
}

.LogsTable_ReloadBtn__Z9reo{
    border: none;
    background: transparent;
    display: inline-block;
    vertical-align: text-bottom;
    opacity: 0.7;
    cursor: pointer;
    padding: 5px;
    padding-bottom: 3px;
}

.LogsTable_ReloadBtn__Z9reo img{
    transition: transform 0.2s ease-in-out;
}

.LogsTable_ReloadBtn__Z9reo:hover img{
    transform: rotate(90deg);
}

.LogsTable_ReloadBtn__Z9reo img{
    width: 20px;
}

.LogsTable_ReloadBtn__Z9reo.LogsTable_Reloading__1imRx img{
    animation:  LogsTable_rotate__18cP6 1s linear;
}


.LogsTable_Details__2Z9gl{
    font-size: 12px;
    grid-column: 1/5;
    margin-top: 7px;
    font-weight: 300;
    color: #e09320;
}

@keyframes LogsTable_rotate__18cP6{
    0% {transform: rotate(90deg);}
    100% {transform: rotate(450deg);}
}
