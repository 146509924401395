.UploadInput{
    background: #FFFFFF;
    border: 2px dashed #E4E4E4;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 20px;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.UploadInput.Highlight{
    border-color: #4A90E2;
}

.FileInput{
    display: none;
}

.InputPlaceholder{
    color: #AEAEAE;
    font-size: 18px;
}

.Browse{
    color: #4A90E2;
    cursor: pointer;
}


.FileName{
    font-size: 14px;
    color: #4A90E2;
    font-weight: 300;
}