.title{
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    color: #232D4C;
    padding:5px;
}

.container{
    text-align: center;
    margin-top:-20px;
}

.subtitle{
    height: 19px;
    font-size: 14px;
    line-height: 135.2%;
    text-align: center;
    color: #000000;
    /* padding-top: 14px; */
    padding: 14px 20px 14px 20px;
}

.button{
    width: 281px;
    height: 64px;
    background: #4A90E2;
    border-radius: 10px;
    color:white;
    margin-top:38px;
}