.StudentListContainer
{
    background: #F4F4F6;
    padding: 64px;
    padding-top:84px;
}

.StudentList{
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}