.LogTableRow{
    grid-template-columns: 2fr 2fr 2fr  3fr 1fr;
}

.Skills{
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
    
}

.Skills span{
    background: #C4C4C4;
    border-radius: 5px;
    padding: 3px 7px;
    font-size: 12px;
    /* cursor: pointer; */
    color: #555;
}

.Logs{
    margin-top: 50px;
}

.ReloadBtn{
    border: none;
    background: transparent;
    display: inline-block;
    vertical-align: text-bottom;
    opacity: 0.7;
    cursor: pointer;
    padding: 5px;
    padding-bottom: 3px;
}

.ReloadBtn img{
    transition: transform 0.2s ease-in-out;
}

.ReloadBtn:hover img{
    transform: rotate(90deg);
}

.ReloadBtn img{
    width: 20px;
}

.ReloadBtn.Reloading img{
    animation:  rotate 1s linear;
}


.Details{
    font-size: 12px;
    grid-column: 1/5;
    margin-top: 7px;
    font-weight: 300;
    color: #e09320;
}

@keyframes rotate{
    0% {transform: rotate(90deg);}
    100% {transform: rotate(450deg);}
}