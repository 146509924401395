.SideBar{
    background: #c4c4c419;
    padding: 70px 40px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: space-between;
}

.Container{
    background: radial-gradient(166.67% 269.75% at 85.98% 167.57%, #DCE8F6 0%, #FFFFFF 100%);
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
}

.Main {
    overflow-y: auto;
    padding: 40px;
}

.Header{
    text-align: center;
}

.Email{
    color:#797979;
}

.Logout{
    border-radius:0;
    border:none;
    text-align: left;
    padding: 10px 20px;
    padding-left: 40px;
    margin-left: -40px;
    width: unset;
    background-color: unset;
    border-radius: 0 3px 3px 0;
    display: flex;
    align-items: center;
    opacity: 0.5;
    color: rgba(0, 0, 0);
    transition: opacity 0.1s ease-in-out;
}

.Logout:hover{
    box-shadow: none;
    background: #ddd; 
    opacity: 0.7;
    transform: none;
}

.LogoutIcon{
    margin-left: 10px;
    opacity: 0.9;
    height: 18px;
}