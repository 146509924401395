.Skill{
    background: #D3D5DB;
    padding: 4px 9px 4px 11px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    display: inline-flex;
    font-size: 14px;
    line-height: 17px;
    color: #232D4C;
}

.RemoveBtn img{
    display: block;
}

.RemoveBtn{
    margin-left: 7px;
    cursor: pointer;
    outline: none;
}