.InputContainer{
    display: inline-flex;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
}

.Input{
    font-size: 16px;
    border:none;
    padding: 11px 16px;
    min-width: 400px;
}

.Input:focus,
.Button:focus{
    outline: none;
}


.InputContainer:focus-within{
    border-color: #4A90E2;;
}

.Button{
    border: none;
    background: #4A90E2;
    border-radius: 0px 5px 5px 0px;
    color: white;
    padding: 16px;
}
