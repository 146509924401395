.FormSection1 {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.25);
    border-radius: 5px;
    padding: 25px 35px;
    padding-top: 15px;

}

.Form {
    max-width: 600px;
    margin: auto;
    margin-top: 40px;
}

.Button{
    max-width: 200px;
    display: block;
    margin:auto;
    margin-top: 20px;
    padding: 15px;
    font-size: 16px;
}